import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import styled from 'styled-components';

import { color, mediaQuery } from '../../../styles/variables';

const MenuList = () => {
  return (
    <Container>
      <ListContainer>
        <ListHead>ピーリング</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-1'}>マッサージピール（PRX-T33）</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-2'}>ウーバーピール</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>イオン導入</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-3'}>イオン導入</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>ピコレーザー</ListHead>
        <List>
          <li>
            <div>トーニング</div>
          </li>
          <li>
            <div>フラクショナル</div>
          </li>
          <li>
            <div>スポット</div>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>ジェネシス (エクセルV+)</ListHead>
        <List>
          <li>
            <div>レーザージェネシス</div>
          </li>
          <li>
            <div>グリーンジェネシス</div>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>レーザー治療</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-4'}>CO2レーザー</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-5'}>Qスイッチルビーレーザー</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-6'}>CO2フラクショナルレーザー</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>HIFU</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-7'}>ソノクイーン</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-8'}>ウルトラセルQプラス</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>フォトフェイシャル</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-9'}>フォトフェイシャル（M-22）</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>ダーマペン</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-11'}>ダーマペン4</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-11'}>ウーバーピール</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-11'}>ベルベットスキン</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-11'}>ヴァンパイアフェイシャル</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-11'}>成長因子（ベネブ社）</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>注入治療</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-12'}>水光注射</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-13'}>ボトックス</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-14'}>ヒアルロン酸</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-16'}>PRP</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>注射・点滴</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-17'}>美容注射・美容点滴</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-18'}>疲労回復ドリップ</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-19'}>プラセンタ注射</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-20'}>高濃度ビタミンC</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>肥満症治療</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-21'}>注射</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-21'}>内服</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>薄毛治療</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-22'}>AGA</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-22'}>FAGA</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>ホームケア</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-23'}>トレチノイン＆ハイドロキノン</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-24'}>美白内服セット</AnchorLink>
          </li>
          <li>
            <AnchorLink to={'/menu/treat#treatment-25'}>その他のお薬</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>低用量ピル</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-26'}>低用量ピル</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>医療機関専売コスメ</ListHead>
        <List>
          <li>
            <AnchorLink to={'/menu/treat#treatment-27'}>ゼオスキンヘルス</AnchorLink>
          </li>
        </List>
      </ListContainer>
      <ListContainer>
        <ListHead>肌診断機</ListHead>
        <List>
          <li>
            <div>VISIA</div>
          </li>
        </List>
      </ListContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const ListContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 60rem;
  margin: 2rem auto;
  padding: 0 1rem;

  @media screen and ${mediaQuery.tablet} {
    display: block;
  }
`;

const ListHead = styled.div`
  flex: 0 0 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.2rem;
  background-color: ${color.pink};
  margin: 0 0.5rem 0 0;

  @media screen and ${mediaQuery.tablet} {
    margin: 0 0 0.5rem 0;
  }
`;

const List = styled.ul`
  list-style: none;
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  padding: 0;
  margin: 0;

  > li {
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      height: 100%;
      background-color: ${color.grey[100]};
      padding: 1rem 0.5rem;
      color: ${color.grey[900]};

      &:hover {
        background-color: ${color.pink};
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      height: 100%;
      background-color: ${color.grey[100]};
      padding: 1rem 0.5rem;
      color: ${color.grey[900]};
    }
  }

  @media screen and ${mediaQuery.tablet} {
    grid-gap: 0.5rem;

    > li {
      > a {
        font-size: 0.8rem;
        padding: 1rem 0.5rem;
      }
    }
  }
`;

export default MenuList;
